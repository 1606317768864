html {
  scroll-behavior: smooth;
}

.card-title {
  font-size: larger;
}

.no-decor {
  text-decoration: none;
}

.mywork-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mywork {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.portfolio-inner-light {
  background: linear-gradient(138deg, #6eece7, #c799ec, #e4e275);
  background-size: 250% 250%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  height: calc(100vh - 100px);
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.portfolio-inner-dark {
  background: rgba(0, 0, 0, 0.801);
  background-size: 250% 250%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  height: calc(100vh - 100px);
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.page {
  min-height: 100vh;
  max-height: fit-content;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-left-50 {
  margin-left: 50px;
}

.top-page2 {
  height: 100vh;
  background-color: red;
}

.body-text-size {
  font-size: 1rem;
}

.logo-lg {
  content: url(./assets/byRobPiovesan-logo.png);
  width: 35vw;
  max-width: 1080px;
  margin-left: 50px;
}

.logo-lg.dark-theme {
  filter: invert(100%);
}

.profile-pic {
  width: 25vw;
  max-width: 350px;
  float: left;
  margin-right: 50px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.white {
  color: white;
}

.black {
  color: black;
}

.block-inline {
  display: inline-block;
}

.margin-5 {
  margin-right: 30px;
}

.margin-bottom {
  margin-bottom: 2vh;
}

.logo-subtext {
  text-align: left;
  margin-left: 50px;
  font-size: xxx-large;
}

.centered-container {
  width: 60%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.text-align-center {
  text-align: center;
}
.segoe {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 50;
}

.font-w-400 {
  font-weight: 400;
}

.text-lg {
  font-size: xx-large;
}

.text-xl {
  font-size: 3rem;
}

.top-nav {
  float: right;
  margin-right: 50px;
  margin-top: 30px;
}

.footer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer.dark {
  background-color: rgb(36, 36, 36);
}

.footer.light {
  background-color: rgb(223, 223, 223);
}

.theme-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  margin-right: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.theme-box {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: 2px solid rgb(255, 255, 255);
  margin-left: 10px;
}

.theme-box.theme-box:hover {
  border-radius: 50%;
}

.theme-box.dark-theme {
  background: rgb(2, 0, 36);
  background: linear-gradient(320deg, #210664, #7a113b);
}

.theme-box.light-theme {
  background: rgb(2, 0, 36);
  background: linear-gradient(138deg, #6eece7, #c799ec, #e4e275);
}

.page-3-dark {
  background-color: #ff0000;
}

.page-3-light {
  background-color: #585858;
}

.page-2-dark {
  background-color: #ffffff00;
}

.page-2-light {
  background-color: #f5f5f500;
}

.about-me {
  width: 60%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.background-particles {
  width: 100%;
  display: "flex";
  flex-grow: "true";
  position: absolute;
  z-index: 0;
}

.linkedin {
  background: url(./assets/linkedin.svg);
  display: inline-block;
  width: 30px;
  height: 30px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin: 15px;
}

.github {
  background: url(./assets/github.svg);
  display: inline-block;
  width: 30px;
  height: 30px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin: 15px;
}

.email {
  background: url(./assets/email.svg);
  display: inline-block;
  width: 30px;
  height: 30px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin: 15px;
}

.email.white {
  filter: invert(100%);
}
.github.white {
  filter: invert(100%);
}
.linkedin.white {
  filter: invert(100%);
}

.return-top-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.up-arrow {
  background: url(./assets/up-arrow.svg);
  display: inline-block;
  width: 50px;
  height: 50px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
}

.up-arrow.dark-theme {
  filter: invert(100%);
}

.inner {
  width: 100%;
  height: 100vh;
  position: relative;
}

.max-width-100 {
  max-width: 80vw;
}

.bg-animation-dark-theme {
  background: linear-gradient(320deg, #210664, #7a113b);
  background-size: 250% 250%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

.bg-animation-dark-theme-2 {
  background: linear-gradient(270deg, #210063, #00577f);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

.bg-animation-portfolio-border-dark {
  background: linear-gradient(138deg, #173130, #2c2235, #313119);
  background-size: 250% 250%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

.bg-animation-light-theme {
  background: linear-gradient(138deg, #6eece7, #c799ec, #e4e275);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

.bg-animation-light-theme-2 {
  background: linear-gradient(320deg, #ebabff, #adecff, #dbfeff);
  background-size: 250% 250%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

.bg-animation-light-theme-3 {
  background: linear-gradient(320deg, #e2e2e2, #b1b1b1);
  background-size: 250% 250%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 600px) {
  .top-nav {
    width: 100vw;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  .theme-selector {
    position: relative;
    float: left;
    margin-left: 30px;
    margin-top: 15px;
  }

  .logo-lg {
    margin-left: 30px;
    width: 70vw;
  }

  .text-lg {
    font-size: 7vw;
    margin-bottom: 8px;
  }

  .logo-subtext {
    text-align: left;
    margin-left: 30px;
  }

  .about-me {
    max-width: 70%;
    min-width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }
  .profile-pic {
    display: none;
  }

  .centered-container {
    width: 60%;
    min-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 2;
  }

  .up-arrow {
    width: 30px;
    height: 30px;
  }

  .text-xl {
    font-size: xx-large;
  }
}

@media screen and (max-width: 350px) and (max-height: 650px) {
  p {
    font-size: small;
  }
  .text-xl {
    font-size: x-large;
  }
}
@media screen and (max-width: 300px) {
  p {
    font-size: x-small;
  }
}

@media screen and (max-height: 580px) {
  p {
    font-size: small;
  }
}

@media (max-width: 300px) {
  .card-text {
    display: none;
  }
  ._card {
    display: contents;
    min-width: 200px;
  }
  .card {
    word-wrap: normal;
  }
}
